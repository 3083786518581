import { useCallback, lazy, useMemo, useEffect, useState } from 'react';
import { cn } from '@divlab/divanui';

import { useRequest } from '@Contexts/Request';
import useNavigation from '@Navigation/useNavigation';
import LayoutMain from '@Layouts/LayoutMain';
import { useCurrentPopup } from '@Promo/Stores/Popups';
import usePage from '@Queries/usePage';
import useMedias from '@Hooks/useMedias';
import { useInfiniteCategory } from '@Queries/useInfiniteCategory';
import usePromoPopup from '@Promo/hooks/usePromoPopup';
import useMiniBanners from '@Promo/hooks/useMiniBanners';
import { useInlineBanners } from '@Queries/Category/useInlineBanners';
import useCategoryPromotion from '@Queries/useCategoryPromotion';
import Suspense from '@Components/Suspense';
import Page404 from '@Pages/Page404';
import ProductCard from '@Components/ProductCard';
import CrossSaleProductCard from '@Components/CrossSaleProductCard';
import SaleBanners from '@Pages/PageCategory/elems/SaleBanners';
import DynamicCatalog from '@Components/DynamicCatalog';
import CategoryBanner from '@Components/CategoryBanner';
import PageTopBar from '@Components/PageTopBar';
import Page from '@Components/Page';
import ErrorBoundary from '@Components/ErrorBoundary';
import FooterText from './elems/FooterText/FooterText';
import SEOAggregateOffer from './elems/SEOAggregateOffer';
import SEOAggregateRating from './elems/SEOAggregateRating';
import PopularPrompts from './elems/PopularPrompts';
import Reviews from './elems/Reviews';
import styles from './PageCategory.module.css';
import './PageCategory.raw.css';

import type { FC, HTMLAttributes } from 'react';
import type { UseInfiniteQueryResult, InfiniteData } from '@tanstack/react-query';
import type { BannerStructureData, CatalogData, RenderProductParams } from '@Types/Catalog';
import type { BasePage } from '@Types/Base';
import type { PageCategoryData } from './typings';
import type { InlineBannerData } from '@Promo/typings';

export interface PageCategoryProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  page: PageCategoryData & BasePage;
  category: UseInfiniteQueryResult<InfiniteData<CatalogData>>;
  slug: string;
  inlineBanners: InlineBannerData[];
  structure: BannerStructureData;
}

const VoiceOfCustomers = lazy(() => import('./VoiceOfCustomers'));
const VoiceOfCustomersManager = lazy(() => import('./elems/VoiceOfCustomersManager'));

const PageCategory: FC<PageCategoryProps> = (props) => {
  const { className, page, category, slug, inlineBanners, structure, ...restProps } = props;
  const { isMobileM } = useMedias();
  const [firstPage] = category.data.pages;
  const lastPage = category.data.pages[category.data.pages.length - 1];
  const isModels = firstPage.productsModel?.length > 0;
  const isCouponCategory = firstPage.isCouponCategory;
  const popup = usePromoPopup({ slug });
  const promotion = useCategoryPromotion({ slug });
  const currentPopup = useCurrentPopup();
  const miniBanners = useMiniBanners({ slug });
  const saleBanners = page.saleBanners || [];
  const [isLoading, setIsLoading] = useState(true);
  const { country } = useRequest();
  const showPopularPrompts =
    country !== 'BLR' && lastPage.page === 1 && !!page.popularPromptsArea?.prompts;
  const showReviews = country !== 'BLR' && lastPage.page === 1 && !!page.reviewsArea?.reviews;
  const showFooterText = lastPage.page === 1 && !!page.footerText?.length;
  const showSEOAreaDivider = !isModels && (showPopularPrompts || showReviews || showFooterText);

  const banners = useMemo(() => {
    if (inlineBanners.length > 0) {
      return inlineBanners;
    }

    return miniBanners;
  }, [inlineBanners, miniBanners]);

  const catalogKey = useMemo(() => {
    return JSON.stringify(structure);
  }, [structure]);

  const renderProduct = useCallback(
    (productProps: RenderProductParams) => {
      if (isMobileM && isModels) {
        return (
          <CrossSaleProductCard
            itemProp='itemListElement'
            itemScope
            itemType='https://schema.org/Offer'
            hiddenCharacteristics
            hiddenBuyLink
            needBuyButton
            needComingSoonInfo
            {...productProps}
          />
        );
      }

      return (
        <ProductCard
          itemProp='itemListElement'
          itemScope
          itemType='https://schema.org/Offer'
          slug={slug}
          tagAnimation={!isModels && !isCouponCategory}
          {...productProps}
        />
      );
    },
    [slug, isMobileM, isModels, isCouponCategory],
  );

  // При подгрузке товаров и последующей перезагрузке страницы, скролл смещается в самый низ и пользователь видит подвал
  // Здесь мы возвращаем пользователя в начало страницы
  useEffect(() => {
    const handleScrollTop = () => {
      window.scrollTo({ top: 0 });

      window.requestAnimationFrame(() => {
        setIsLoading(false);
      });
    };

    window.addEventListener('load', handleScrollTop);

    return () => window.removeEventListener('load', handleScrollTop);
  }, []);

  return (
    <div itemScope itemType='http://schema.org/Product'>
      <PageTopBar
        title={page.meta.h1}
        titleAlign='leftOnMobile'
        breadcrumbs={page.breadcrumbs}
        banner={
          promotion.data && (
            <CategoryBanner banner={promotion.data.materials[0]} title={page.meta.h1} />
          )
        }
        breadcrumbsSeoData={page.breadcrumbsSeoData}
      />
      {firstPage.seo && <SEOAggregateOffer seo={firstPage.seo} />}
      <div {...restProps} className={cn(styles.page, className)} data-testid='page-category'>
        <Suspense fallback={null}>
          {popup}
          {!currentPopup && (
            <VoiceOfCustomers slug={slug} categoryName={page.categoryName} isModels={isModels} />
          )}
        </Suspense>

        {saleBanners.length > 0 && (
          <SaleBanners className={styles.saleBanners} banners={saleBanners} />
        )}

        <div itemScope itemType='https://schema.org/OfferCatalog'>
          <DynamicCatalog
            key={catalogKey}
            categoryName={page.categoryName}
            pageData={{
              analyticsTitle: page.page === 'Showroom' ? 'Шоурум' : page.categoryName,
              breadcrumbs: page.breadcrumbs,
              title: page.meta.title,
            }}
            renderProduct={renderProduct}
            banners={banners}
            slug={slug}
            needPaginator
            isModels={isModels}
            category={category}
            popularLinks={page.popularLinks}
            groups={page.groups}
            rubrics={page.rubrics}
            defaultStructure={structure}
            isLoadingPage={isLoading}
          />
        </div>

        {showSEOAreaDivider && <div className={styles.divider} />}

        {showPopularPrompts && (
          <PopularPrompts
            popularPromptsArea={page.popularPromptsArea}
            view={isMobileM ? 'scroll' : 'list'}
          />
        )}

        {showReviews && (
          <ErrorBoundary fallback={null}>
            <Reviews reviewsArea={page.reviewsArea} category={page.categoryTranslite} />
          </ErrorBoundary>
        )}

        {showFooterText && (
          <FooterText
            itemProp='description'
            texts={page.footerText}
            className={cn(styles.footerText, 'ShortText')}
          />
        )}
      </div>
      {firstPage.seo && <SEOAggregateRating seo={firstPage.seo} />}
    </div>
  );
};

const PageCategoryContainer: FC = () => {
  const {
    params: { slug },
  } = useNavigation();
  const page = usePage<PageCategoryData>();
  const category = useInfiniteCategory({ slug });
  const [firstPage] = category.data?.pages || [];
  const { data: inlineBanners } = useInlineBanners(slug);
  const { banners = [], structure } = inlineBanners || {};
  const { isMobileM, isDesktop } = useMedias();

  const getBannersPerPage = () => {
    if (isMobileM) {
      return 12;
    }
    if (isDesktop) {
      return 6;
    }
    return 5;
  };

  const bannersPerPage = getBannersPerPage();
  const bannersFromCurrentPage = banners?.slice((firstPage.page - 1) * bannersPerPage);

  if (!category.data || !firstPage) return <Page404 />;
  if (firstPage.page > firstPage.pageTotalCount) return <Page404 />;

  return (
    <LayoutMain>
      <Page>
        <PageCategory
          page={page.data}
          inlineBanners={bannersFromCurrentPage}
          structure={structure}
          category={category}
          slug={slug}
          data-testid='page-category'
        />
        <Suspense fallback={null}>
          <VoiceOfCustomersManager id='categoryVoc' />
        </Suspense>
      </Page>
    </LayoutMain>
  );
};

export default PageCategoryContainer;
