export default (name: string) => {
  const newName = (name || '').replace(/\s+/g, ' ').trim() || 'Гость';
  const firstTwoWords = newName.split(' ').slice(0, 2);
  const initials = firstTwoWords.map((word) => word[0].toUpperCase()).join('');
  const shortName = firstTwoWords[0] + (firstTwoWords[1] ? ` ${firstTwoWords[1][0]}.` : '');

  return {
    initials,
    shortName,
  };
};
